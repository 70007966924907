export const items = [
	{
		key: "home",
		label: "Home",
		path: "/",
	},
	{
		key: "information",
		label: "Information",
		path: "/information",
	},
	{
		key: "contact",
		label: "Contact",
		path: "/contact",
	},
];
